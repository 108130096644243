<template>
  <component
    :is="generic ? `div` : `h${importance}`"
    ref="heading"
    class="relative font-gt-flexa-light lg:mb-10"
    :class="[
      {
        'mb-6 text-4xl lg:text-6xl': importance === '1',
        'mb-8 text-3xl lg:text-5xl': importance === '2',
        'mb-8 text-2xl lg:text-4xl': importance === '3'
      }
    ]"
  >
    <span class="inline-block overflow-hidden">
      <Transition :name="hasTransition">
        <span v-show="isVisible" class="inline-block">
          <span v-html="titleStyled.html"></span>
        </span>
      </Transition>
      <span v-if="arrow" v-html="arrow.html" class="absolute" :class="arrow.class"></span>
    </span>
  </component>
</template>

<script setup lang="ts">
interface Arrow {
  class: string
  html: string
}

interface Props {
  importance?: '1' | '2' | '3'
  content: string
  arrow?: Arrow
  generic?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  generic: true,
  importance: '2'
})

const heading = ref(null)
const isVisible = ref(false)

const { replaceBrackets } = useText()
const titleStyled = replaceBrackets(props.content)

const hasTransition = computed(() => (props.importance === '1' ? 'fade' : 'none'))

onMounted(() => {
  isVisible.value = true
})
</script>
