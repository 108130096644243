export default function useText() {
  function replaceBrackets(text: string) {
    let length

    const html = text.replace(/\[(.+?)\]/g, (string, _) => {
      const words = string.split(',')
      length = words.length

      const setArray = words.map(
        (x: string, idx: number) =>
          `<span class="ww"><span data-w-idx="${idx}" class="w">${x}</span></span>`
      )

      return (
        '<strong class="font-gt-flexa-bold-italic">' +
        string.replace(string, setArray.join('').replace('[', '').replace(']', '')) +
        '</strong>'
      )
    })

    return {
      length,
      html
    }
  }

  function cleanSSR(text: string) {
    const textStripped = text.replace(/(<([^>]+)>)/gi, '')
    const textCleaned = textStripped.replace(/\[(.+?)\]/g, (_, first) => {
      const arrayWords = first.split(',')
      return arrayWords[0]
    })

    return textCleaned
  }

  return {
    replaceBrackets,
    cleanSSR
  }
}
